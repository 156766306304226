import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ReactPlayer from 'react-player';
import { TimelineLite } from 'gsap/TweenMax';
import ScrollMagic from 'scrollmagic';
import ReactFullpage from '@fullpage/react-fullpage';
import StyledAboutSection1 from '../components/Landing/AboutSection1';
import StyledAboutSection2 from '../components/Landing/AboutSection2';
import StyledAboutSection3 from '../components/Landing/AboutSection3';
import StyledLandingVideoCover from '../components/Landing/LandingVideoCover';
import GraphiteImage from '../images/graphite.png';
import ArcelorMittal from '../svg/ArcelorMittal.inline.svg';
import Gerdau from '../svg/Gerdau.inline.svg';
import Nucor from '../svg/Nucor.inline.svg';
import RioTinto from '../svg/RioTinto.inline.svg';
import Schnitzer from '../svg/Schnitzer.inline.svg';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import { GlobalDispatchContext } from '../context/GlobalContextProvider';
import ScrollAnimationIcon from '../components/Landing/ScrollAnimationIcon';
import Footer from '../components/Footer';

const Section1 = styled.section`
  min-height: calc(100vh - 75px);
  padding-top: 75px;
  width: 100%;
  background: #080808;
  display: flex;
  overflow: hidden;
  justify-content: center;
  position: relative;
  flex-direction: column;
`;


const HeroWrapper = styled.div`
  display: grid;
  position: relative;
  width: 95%;
  max-width: 2000px;
  margin: auto;
  justify-content: space-evenly;
  flex: 1;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  
`;

const StyledGraphiteImage = styled.img`
  width: 700px;
  opacity: 0;
  pointer-events: none;
  grid-column: 2;
  grid-row: 1 / 3;
  align-self: center;
  justify-self: center;
  position: relative;
  top: 50px;
  
  @media screen and (max-width: 1540px) {
    max-width: 600px;
  }
  
  @media screen and (max-width: 1300px) {
    max-width: 550px;
  }
  
  @media screen and (max-width: 1150px) {
    max-width: 480px;
  }

  @media screen and (max-width: 1024px) {
    flex-shrink: 1;
    position: static;
    margin: 50px 0 25px;
    max-width: 80%;
  }
  
  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

const HeroHeader = styled.h1`
  font-family: 'Georgia', sans-serif;
  font-weight: 300;
  font-size: 134rem;
  color: #aed9e0;
  margin: 0;
  overflow: hidden;
  position: relative;
  opacity: 0;
  width: 680px;
  align-self: flex-end;
  
  @media screen and (max-width: 1540px) {
    font-size: 110rem;
    width: 580px;
  }
  
  @media screen and (max-width: 1300px) {
    font-size: 100rem;
    width: 550px;
  }
  
  @media screen and (max-width: 1150px) {
    font-size: 95rem;
    width: 500px;
  }
  
  @media screen and (max-width: 1024px) {
    font-size: 90rem;
    text-align: center;
    width: auto;
    align-self: center;
  }
  
  @media screen and (max-width: 900px) {
    font-size: 70rem;
  }
  
  @media screen and (max-width: 720px) {
    font-size: 64rem;
  }
  
  @media screen and (max-width: 400px) {
    font-size: 56rem;
  }
`;

const HeroSubheading = styled.h2`
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 52rem;
  color: #f4faff;
  margin: 0;
  overflow: hidden;
  width: 750px;
  position: relative;
  opacity: 0;
  
  @media screen and (max-width: 1540px) {
    font-size: 42rem;
    width: 600px;
  }
  
  @media screen and (max-width: 1300px) {
    font-size: 38rem;
    width: 550px;
  }
  
  @media screen and (max-width: 1150px) {
    font-size: 34rem;
    width: 500px;
  }
  
  @media screen and (max-width: 1024px) {
    font-size: 30rem;
    width: auto;
    text-align: center;
  }
  
  @media screen and (max-width: 720px) {
    font-size: 24rem;
  }
  
  @media screen and (max-width: 400px) {
    font-size: 22rem;
  }
`;

const HeroTextContainer = styled.div`
  grid-column: 1;
  grid-row: 1 / 3;
  width: auto;
  align-self: center;
`;

const ScrollIconWrapper = styled.div`
  flex: 0;
  opacity: 0;
  display: block;
  grid-row: 3;
  grid-column: 1 / 3;
  align-self: end;
  padding: 10px 0 10px 0;

  @media screen and (max-width: 1024px) {
    align-self: center;
  }
`;

const VideoSection = styled.section`
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  background: #080808;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const VideoFrame = styled(ReactPlayer)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  filter: opacity(.3);
  transition: visibility 0s, opacity 0.5s linear;
  opacity: ${props => props.loading ? '0' : '1'};
  visibility: ${props => props.loading ? 'hidden' : 'visible'};
`;

const CollaboratorContainer = styled.div`
  display: flex;
  position: relative;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  opacity: 0;
  top: 25px;
  margin-bottom: 25px;
  
  & > * {
    margin: 0 15px;
    width: 150px;
    pointer-events: none;
  }
  
  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
    
    & > * {
      width: 25%;
      margin: 10px 15px;
    }
  }
  
  @media screen and (max-width: 400px) {
    & > * {
      width: 20%;
    }
  }
`;

const StyledNucor = styled(Nucor)`
  padding-bottom: 4px;
`;

const VideoOverlayText = styled.p`
  font-family: 'Georgia', sans-serif;
  position: relative;
  font-weight: 300;
  font-size: 40rem;
  color: #f4faff;
  margin: 0 0 25px;
  word-break: keep-all;
  opacity: 0;
  max-width: 1024px;
  text-align: center;
  top: 25px;
  
  @media screen and (max-width: 900px) {
    font-size: 40rem;
    width: 90%;
  }
  
  @media screen and (max-width: 750px) {
    font-size: 30rem;
  }
  
  @media screen and (max-width: 400px) {
    font-size: 24rem;
  }
`;

const TrustedByText = styled.p`
  font-family: 'Lato', sans-serif;
  position: relative;
  font-weight: 300;
  font-size: 20rem;
  color: #f4faff;
  text-align: center;
  top: 25px;
  opacity: 0;
  margin-bottom: 0;
`;

const AboutSection = styled.section`
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const IndexPage = () => {
  const [videoLoading, setVideoLoading] = useState(true);
  const [hasRan, setHasRan] = useState(false);

  const [screenSize, setScreenSize] = useState({
    height: 0,
    width: 0,
  });

  const responsiveWidth = 900;
  const responsiveHeight = 600;

  const landingElements = [];
  const videoElements = [];
  const sections = [];

  const landingTween = new TimelineLite();
  const videoTween = new TimelineLite();

  const dispatch = useContext(GlobalDispatchContext);
  let controller;

  if (typeof window !== 'undefined') {
    controller = new ScrollMagic.Controller();
  }

  const updateScreenSize = (width, height) => {
    setScreenSize({ width: width, height: height });

    if (width < 1000 || width > 2144) {
      setVideoLoading(true);
    }

    if (width <= responsiveWidth || height <= responsiveHeight)
      dispatch({ type: 'NAV_COLOR_CHANGE', color: 'rgba(8, 8, 8, 0.8)' });
  };

  useEffect(() => {
    if (!hasRan) {
      setHasRan(true);
      dispatch({ type: 'NAV_COLOR_CHANGE', color: 'transparent' });

      updateScreenSize(window.innerWidth, window.innerHeight);

      landingTween.staggerTo(landingElements, 2.0, {
        autoAlpha: 1,
        delay: 0.8
      }, 0.2);

      videoTween.staggerTo(videoElements, 0.9, {
          autoAlpha: 1,
          y: -25,
        }, 0.3,
      );

      new ScrollMagic.Scene({
        triggerElement: '#video-section p',
      })
        .setTween(videoTween)
        .addTo(controller)
        .reverse(false);
    }
  });

  return (
    <Layout>
      <SEO
        title="Graphite Electrode and Refractory Products"
        description="Ceramark Technology Inc. is a leading supplier of graphite
        and refractory products across North America"
      />
      <ReactFullpage
        licenseKey="16F63878-C7124F3F-B55D0293-02FD7476"
        lazyLoading={false}
        responsiveWidth={responsiveWidth}
        responsiveHeight={responsiveHeight}
        verticalCentered={false}
        scrollingSpeed={1000}
        afterResize={updateScreenSize}
        afterResponsive={
          (isResponsive) => {
            dispatch(
              {
                type: 'NAV_COLOR_CHANGE',
                color: `rgba(8, 8, 8, ${isResponsive ? 0.8 : 0})`,
              },
            );
          }
        }
        render={() => (
          <React.Fragment>
            <Section1
              className="section fp-auto-height-responsive"
              id="section-1"
              ref={sec => sections[0] = sec}
            >
              <HeroWrapper>
                <HeroTextContainer>
                  <HeroHeader ref={li => landingElements[1] = li}>
                    Engineered For <span style={{ color: '#fe5f55' }}>Heat </span>
                  </HeroHeader>
                  <HeroSubheading ref={li => landingElements[2] = li}>
                    Ceramark is a leading supplier of graphite and
                    refractory products
                  </HeroSubheading>
                </HeroTextContainer>
                <StyledGraphiteImage src={GraphiteImage} ref={i => landingElements[0] = i}/>
                <ScrollIconWrapper ref={i => landingElements[3] = i}>
                  <ScrollAnimationIcon/>
                </ScrollIconWrapper>
              </HeroWrapper>
            </Section1>
            <VideoSection className="section fp-auto-height-responsive" id="video-section">
              {(screenSize.width >= 1000 && screenSize.width <= 2144) &&
              <VideoFrame
                loading={videoLoading}
                url='https://www.youtube.com/watch?v=zyLQt56QZQo'
                playing
                loop
                controls={false}
                width="2144px"
                height="1206px"
                volume={0}
                muted={true}
                config={{
                  youtube: {
                    playerVars: {
                      showinfo: 0,
                      mute: 1,
                      autoplay: 1,
                      disablekb: 1,
                      enablejsapi: 1,
                      autohide: 1,
                    },
                  },
                }}
                onStart={() => {
                  setTimeout(() => setVideoLoading(false), 3000);
                }}

                onReady={
                  (e) => {
                    e.wrapper.firstChild.firstChild.setAttribute('data-keepplaying', '');
                    e.wrapper.firstChild.firstChild.setAttribute('tabindex', '-1');
                  }
                }
                autoPlay
              />
              }
              <StyledLandingVideoCover
                loading={videoLoading}
                key={videoLoading ? 'loading' : 'done'}
              />
              <VideoOverlayText ref={v => videoElements[0] = v}>
                As a partner to the world's largest steel, metallurgical,
                and mining companies, Ceramark delivers unmatched customer
                satisfaction through highly dependable, cost-efficient
                products and solutions.
              </VideoOverlayText>
              <TrustedByText ref={v => videoElements[1] = v}>
                Trusted By
              </TrustedByText>
              <CollaboratorContainer ref={v => videoElements[2] = v}>
                <ArcelorMittal/>
                <Gerdau/>
                <StyledNucor/>
                <RioTinto/>
                <Schnitzer/>
              </CollaboratorContainer>
            </VideoSection>
            <AboutSection className="section fp-auto-height-responsive" id="about-section-1"
                          ref={sec => sections[2] = sec}>
              <StyledAboutSection1 controller={controller}/>
            </AboutSection>
            <AboutSection className="section fp-auto-height-responsive" id="about-section-2"
                          ref={sec => sections[3] = sec}>
              <StyledAboutSection2 controller={controller}/>
            </AboutSection>
            <AboutSection className="section fp-auto-height-responsive" id="about-section-3"
                          ref={sec => sections[4] = sec}>
              <StyledAboutSection3 controller={controller}/>
            </AboutSection>
            <Footer className="section fp-auto-height"/>
          </React.Fragment>
        )}
      />
    </Layout>
  );
};

export default IndexPage;
