import React from "react";
import styled, { keyframes } from "styled-components";

const Animation = keyframes`
   0%   { transform: translateY(0); opacity: 0; }
  10%  {  transform: translateY(0); opacity: 1; }
  30%  { transform: translateY(0); height: 6px; }
  50%  { transform: translateY(0); height: 30px; }
  70%  { transform: translateY(20px); height: 6px; opacity: 1; }
  90%  { opacity: 0; }
  100% { transform: translateY(20px); opacity: 0;}
`;

const Mouse = styled.span`
  display: block;
  position: relative;
  height: 48px;
  width: 24px;
  border: 2px solid #575757;
  border-radius: 16px;
  margin: auto;
`;

const Scroller = styled.span`
  display: block;
  position: absolute;
  left: 50%;
  background: #575757;
  height: 6px;
  width: 4px;
  top: 9.6px;
  margin-left: -2px;
  border-radius: 30%;
  transform-origin: top center;
  backface-visibility: hidden;
  animation: ${Animation} 2.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
`;

const ScrollText = styled.h6`
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  color: #8a8d90;
  letter-spacing: 2px;
  margin-top: 15px;
`;


const ScrollAnimationIcon = () => {
  return (
    <>
      <Mouse>
        <Scroller/>
      </Mouse>
      <ScrollText>
        SCROLL DOWN
      </ScrollText>
    </>
  );
};

export default ScrollAnimationIcon;
