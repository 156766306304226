import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import BackgroundImage from 'gatsby-background-image';

const LandingVideoCover = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "VideoCover.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        
        tablet: file(relativePath: { eq: "VideoCover.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        
        mobile: file(relativePath: { eq: "VideoCover.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={
      data => {
        const sources = [
          data.mobile.childImageSharp.fluid,
          {
            ...data.tablet.childImageSharp.fluid,
            media: `(min-width: 700px)`,
          },

          {
            ...data.desktop.childImageSharp.fluid,
            media: `(min-width: 1000px)`,
          },
        ];

        return (
          <BackgroundImage
            Tag="div"
            className={className}
            fluid={sources}
            fadeIn={false}
            loading="eager"
          >
          </BackgroundImage>
        );
      }
    }
  />
);

const StyledLandingVideoCover = styled(LandingVideoCover)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: opacity(.3);
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute !important;
  transition: visibility 0.5s, opacity 0.5s linear;
  opacity: ${props => props.loading ? '1' : '0'};
  visibility: ${props => props.loading ? 'visible' : 'hidden'};
`;

export default StyledLandingVideoCover;
