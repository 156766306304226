import { graphql, StaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TimelineLite } from 'gsap/TweenMax';

import BackgroundImage from 'gatsby-background-image';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import ScrollMagic from 'scrollmagic';

const AboutSection2 = ({ className, controller }) => {
  const tween = new TimelineLite();
  const elements = [];

  useEffect(() => {

    tween.staggerTo(elements, 0.9, {
        delay: 0.5,
        autoAlpha: 1,
        y: -25,
      }, 0.3,
    );

    new ScrollMagic.Scene({
      triggerElement: '#about-section-2',
    })
      .setTween(tween)
      .addTo(controller)
      .reverse(false);
  });

  return (
    <StaticQuery
      query={graphql`
      query {
        desktop: file(relativePath: { eq: "Landing2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        
        tablet: file(relativePath: { eq: "Landing2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        
        mobile: file(relativePath: { eq: "Landing2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
      render={data => {
        const sources = [
          data.mobile.childImageSharp.fluid,
          {
            ...data.tablet.childImageSharp.fluid,
            media: `(min-width: 700px)`,
          },

          {
            ...data.desktop.childImageSharp.fluid,
            media: `(min-width: 1000px)`,
          },
        ];

        return (
          <BackgroundImage
            Tag="div"
            className={className}
            fluid={sources}
            fadeIn={false}
            loading="eager"
          >
            <AboutSectionContentWrapper>
              <AboutSectionHeader ref={e => elements[0] = e}>
                Unparalleled Sourcing Network
              </AboutSectionHeader>
              <div>
                <AboutSectionBody ref={e => elements[1] = e}>
                  We draw from the broadest network of manufacturers, which
                  gives us access to the widest selection of options.
                  This allows us to put our sourcing through the most
                  rigorous vetting process in the industry.
                </AboutSectionBody>
                <AboutSectionBody ref={e => elements[2] = e}>
                  Close collaboration with our manufacturers is just as
                  crucial of a process: we have worked with trusted
                  manufacturers for decades in detailing and delivering
                  quality product.
                </AboutSectionBody>
              </div>
            </AboutSectionContentWrapper>
          </BackgroundImage>
        );
      }}
    />
  );
};

const StyledAboutSection2 = styled(AboutSection2)`
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
`;

const AboutSectionContentWrapper = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
  
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: baseline;
    flex-basis: 0;
  }
`;

const AboutSectionHeader = styled.h1`
  font-family: 'Georgia', sans-serif;
  font-weight: 300;
  font-size: 90rem;
  color: #f4faff;
  width: 550px;
  position: relative;
  top: 25px;
  opacity: 0;
  
  @media screen and (max-width: 1600px) {
    font-size: 74rem;
    width: 450px;
  }
  
  @media screen and (max-width: 1120px) {
    font-size: 68rem;
    width: 400px;
  }
  
  @media screen and (max-width: 1000px) {
    font-size: 66rem;
    width: 650px;
  }
  
  @media screen and (max-width: 800px) {
    font-size: 60rem;
    width: 600px;
  }
  
  @media screen and (max-width: 620px) {
    font-size: 55rem;
    width: 400px;
  }
  
  @media screen and (max-width: 420px) {
    font-size: 50rem;
    width: 300px;
  }
`;

const AboutSectionBody = styled.p`
  font-family: 'Lato', sans-serif;
  color: #f4faff;
  font-size: 30rem;
  width: 825px;
  position: relative;
  top: 25px;
  opacity: 0;

  @media screen and (max-width: 1600px) {
    font-size: 24rem;
    width: 650px;
  }
  
  @media screen and (max-width: 1120px) {
    font-size: 22rem;
    width: 600px;
  }
  
  @media screen and (max-width: 800px) {
    width: 600px;
  }
  
  @media screen and (max-width: 620px) {
    font-size: 20rem;
    width: 400px;
  }
  
  @media screen and (max-width: 420px) {
    width: 300px;
  }
`;

export default StyledAboutSection2;