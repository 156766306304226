import { graphql, StaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TimelineLite } from 'gsap/TweenMax';

import BackgroundImage from 'gatsby-background-image';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import ScrollMagic from 'scrollmagic';

const AboutSection1 = ({ className, controller }) => {
  const tween = new TimelineLite();
  const elements = [];

  useEffect(() => {

    tween.staggerTo(elements, 0.9, {
        delay: 0.5,
        autoAlpha: 1,
        y: -25,
      }, 0.3,
    );

    new ScrollMagic.Scene({
      triggerElement: '#about-section-1',
    })
      .setTween(tween)
      .addTo(controller)
      .reverse(false);
  });

  return (
    <StaticQuery
      query={graphql`
      query {
        desktop: file(relativePath: { eq: "Landing1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        
        tablet: file(relativePath: { eq: "Landing1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        
        mobile: file(relativePath: { eq: "Landing1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
      render={data => {
        const sources = [
          data.mobile.childImageSharp.fluid,
          {
            ...data.tablet.childImageSharp.fluid,
            media: `(min-width: 700px)`,
          },

          {
            ...data.desktop.childImageSharp.fluid,
            media: `(min-width: 1000px)`,
          },
        ];

        return (
          <BackgroundImage
            Tag="div"
            fluid={sources}
            className={className}
            fadeIn={false}
            loading="eager"
          >
            <AboutSectionContentWrapper>
              <AboutSectionHeader ref={e => elements[0] = e}>
                Expertise in Engineering Solutions
              </AboutSectionHeader>
              <div>
                <AboutSectionBody ref={e => elements[1] = e}>
                  We make it our top priority to thoroughly understand the
                  technical requirements of our products for every
                  application in every location they are used. In our 25
                  years of operation, we have served over 100 steel mills
                  and foundries.
                </AboutSectionBody>
                <AboutSectionBody ref={e => elements[2] = e}>
                  Our focus is on building on and sharpening our expertise:
                  we take every opportunity to visit production teams on site
                  to further understand how to provide more value as partners.
                </AboutSectionBody>
              </div>
            </AboutSectionContentWrapper>
          </BackgroundImage>
        );
      }}
    />
  );
};

const StyledAboutSection1 = styled(AboutSection1)`
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
`;

const AboutSectionContentWrapper = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
  
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: baseline;
    flex-basis: 0;
  }
`;

const AboutSectionHeader = styled.h1`
  font-family: 'Georgia', sans-serif;
  font-weight: 300;
  font-size: 90rem;
  color: #f4faff;
  width: 550px;
  position: relative;
  top: 25px;
  opacity: 0;
  
  @media screen and (max-width: 1600px) {
    font-size: 74rem;
    width: 450px;
  }
  
  @media screen and (max-width: 1120px) {
    font-size: 68rem;
    width: 400px;
  }
  
  @media screen and (max-width: 1000px) {
    font-size: 66rem;
    width: 650px;
  }
  
  @media screen and (max-width: 800px) {
    font-size: 60rem;
    width: 600px;
  }
  
  @media screen and (max-width: 620px) {
    font-size: 55rem;
    width: 400px;
  }
  
  @media screen and (max-width: 420px) {
    font-size: 50rem;
    width: 300px;
  }
`;

const AboutSectionBody = styled.p`
  font-family: 'Lato', sans-serif;
  color: #f4faff;
  font-size: 30rem;
  width: 825px;
  position: relative;
  top: 25px;
  opacity: 0;
  
  @media screen and (max-width: 1600px) {
    font-size: 24rem;
    width: 650px;
  }
  
  @media screen and (max-width: 1120px) {
    font-size: 22rem;
    width: 600px;
  }
  
  @media screen and (max-width: 800px) {
    width: 600px;
  }
  
  @media screen and (max-width: 620px) {
    font-size: 20rem;
    width: 400px;
  }
  
  @media screen and (max-width: 420px) {
    width: 300px;
  }
`;

export default StyledAboutSection1;